import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const xsIcon = defineStyle({
  fontSize: '20px',
  h: '24px',
})

const solid = defineStyle({
  background: 'brand.primary.500',
  color: 'standard.white',
  borderColor: 'brand.primary.500',
})

const ghost = defineStyle({
  background: 'none',
  border: 'none',
  color: 'brand.primary.500',
  _disabled: {
    background: 'none',
    border: 'none',
    color: 'interaction.support.placeholder',
  },
})

const outlineEmpty = defineStyle({
  border: '2.5px solid',
  borderColor: 'interaction.support.unselected',
})

const outlineDisabled = defineStyle({
  border: '2.5px solid',
  borderColor: 'interaction.support.disabled',
})

const hidden = defineStyle({
  background: 'none',
  border: 'none',
  padding: 0,
})

const sm = defineStyle({
  paddingX: 4,
  paddingY: '0.625rem',
})

export const Button = defineStyleConfig({
  sizes: { xsIcon, sm },
  variants: { solid, ghost, outlineEmpty, outlineDisabled, hidden },
  defaultProps: {
    size: 'sm',
  },
})
