import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const helpers = createMultiStyleConfigHelpers(['root'])

export const AdminContainer = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      p: '1.5rem',
      mt: '1.5rem',
      display: 'flex',
      flexDir: 'column',
      position: 'relative',
      flexGrow: 1,
      flex: '1 1 auto',
      w: '65rem',
      minW: '50rem',
      maxW: '100vw',
      overflowX: 'scroll',
    },
    editableInput: {
      px: '4',
      py: '0.375rem',
      borderRadius: '4',
      borderWidth: '1.5px',
      borderColor: 'base.divider.brand',
      bgColor: 'standard.white',
      _focusVisible: {
        boxShadow: 'none',
      },
    },
  },
  variants: {
    fullPageEmptyBg: {
      root: {
        w: '100%',
        p: '0',
        mt: 0,
      },
    },
    rootWithTableLook: {
      root: {
        bg: 'standard.white',
        borderTopLeftRadius: '1rem',
        borderTopRightRadius: '1rem',
      },
    },
    fullPage: {
      root: {
        width: '100%',
        bgColor: 'standard.white',
        mt: 0,
        p: 0,
      },
    },
  },
})
